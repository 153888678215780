<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="isLoading">
          <Grid
            class="grid-full-height"
            :data-items="result"
            :columns="columns"
            :column-menu="true"
            :take="dataState.take"
            :skip="dataState.skip"
            :sort="dataState.sort"
            :filter="dataState.filter"
            :group="dataState.group"
            :sortable="true"
            :reorderable="true"
            :resizable="true"
            :groupable="false"
            :pageable="true"
            :filterable="true"
            :page-size="50"
            :cell-render="'defaultCellTemplate'"
            @datastatechange="onDataStateChange"
            @filterchange="onFilterChange"
            @sortchange="onSortChange"
            @columnreorder="onColumnReorder"
          >
            <template v-slot:filterSlotTemplate="{ props }">
              <div>
                <dropdownlist
                  :data-items="statusFilterItems"
                  :text-field="'text'"
                  :popup-settings="{ width: '' }"
                  class="k-dropdown-operator"
                  icon-class-name="k-i-filter k-icon"
                  :value="selectedStatusFilterItem"
                  @change="(ev) => {
                    if (ev.target.value.id === '900') { // special filter
                      props.onChange({ operator: 'neq', field: props.field, value: '0', event: ev });
                    }
                    else {
                      props.onChange({ operator: 'eq', field: props.field, value: ev.target.value.id, event: ev });
                    }
                    selectedStatusFilterItemId = ev.target.value.id;
                  }"
                />

                <button
                  v-if="props.operator"
                  title="Clear"
                  type="button"
                  class="k-button k-button-icon k-clear-button-visible"
                  @click="(ev) => {
                    props.onChange({ operator: '', field: props.field, value: null, event: ev })
                    selectedStatusFilterItemId = null;
                  }"
                >
                  <span class="k-icon k-i-filter-clear" />
                </button>
              </div>
            </template>

            <!-- Custom toolbar -->
            <GridToolbar>

              <toolbar-item-view-manager
                :grid-id="gridId"
                :columns="columns"
                :data-state="dataState"
                :original-columns="originalColumns"
                :original-data-state="originalDataState"
                :current-view-name="currentViewName"
                :current-view-id="currentViewId"
                @resetToDefaultViewClick="resetToDefaultView"
                @applyView="onApplyView"
              />

              <toolbar-item-column-manager
                :columns="activeColumns"
                :original-columns="originalColumns"
                @columnssubmit="columns = $event"
              />

              <toolbar-item-export-excel
                :data-items="filteredDataItems"
                :columns="activeColumns"
                :data-state="dataState"
              />

              <template>
                <div style="margin-left: auto">
                  <b-button-group>
                    <b-button
                      size="sm"
                      :variant="selectedFilter === 'active' ? 'primary' : 'secondary'"
                      @click="selectedFilter = 'active'"
                    >
                      Active
                    </b-button>
                    <b-button
                      size="sm"
                      :variant="selectedFilter === 'oldData' ? 'primary' : 'secondary'"
                      @click="selectedFilter = 'oldData'"
                    >
                      Old data
                    </b-button>
                  </b-button-group>
                </div>
              </template>
            </GridToolbar>

            <!-- Custom action cell -->

            <template v-slot:defaultCellTemplate="{ props }">
              <UiAttributeCellBaseTemplate
                :field="props.field"
                :row-type="props.rowType"
                :class-name="props.className"
                :format="props.format"
                :type="props.type"
                :data-item="props.dataItem"
              />
            </template>

            <template v-slot:cellActionTemplate="{ props }">
              <DetailLinkActionCellTemplate
                :field="props.field"
                :row-type="props.rowType"
                :class-name="props.className"
                :to="getAgentDetailRoute(props.dataItem)"
                :to-params="{ id: props.dataItem.id }"
              />
            </template>

            <template v-slot:cellAsupHostStatusTemplate="{ props }">
              <AsupStatusCellTemplate
                :field="props.field"
                :row-type="props.rowType"
                :asup-id="props.dataItem.id"
                :asup-status="props.dataItem.hostMonitorStatus"
                :is-host-status="true"
              />
            </template>

            <template v-slot:cellAsupTenantStatusTemplate="{ props }">
              <AsupStatusCellTemplate
                :field="props.field"
                :row-type="props.rowType"
                :asup-id="props.dataItem.id"
                :asup-status="props.dataItem.tenantMonitorStatus"
                :is-host-status="false"
                :tenant-id="props.dataItem.tenantId"
              />
            </template>

            <template v-slot:cellfreeSpaceTemplate="{ props }">
              <HumanizedByteSizeCellTemplate
                :field="props.field"
                :row-type="props.rowType"
                :format="props.format"
                :type="props.type"
                :data-item="props.dataItem"
                :value="props.dataItem.availableFreeSpaceBytes"
              />
            </template>

          </Grid>
        </b-overlay>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {
  BCol, BRow, BButtonGroup, BButton, BOverlay,
} from 'bootstrap-vue'
import { process } from '@progress/kendo-data-query'
import { Grid, GridToolbar } from '@progress/kendo-vue-grid'
import { DropDownList } from '@progress/kendo-vue-dropdowns'
import { mapGetters } from 'vuex'
import {
  DetailLinkActionCellTemplate, ToolbarItemViewManager, ToolbarItemColumnManager, ToolbarItemExportExcel, AsupStatusCellTemplate, UiAttributeCellBaseTemplate, HumanizedByteSizeCellTemplate,
} from '@/components/grid'
import {
  GridDefaultMixin, GridPreserveStateMixin,
} from '@/mixins/grid'

import AgentService from '@/service/agent.service'

import moment from '@/libs/moment'

export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BOverlay,
    Grid,
    GridToolbar,
    DetailLinkActionCellTemplate,
    AsupStatusCellTemplate,
    ToolbarItemExportExcel,
    ToolbarItemColumnManager,
    ToolbarItemViewManager,
    UiAttributeCellBaseTemplate,
    HumanizedByteSizeCellTemplate,
    dropdownlist: DropDownList,
  },
  mixins: [
    GridDefaultMixin,
    GridPreserveStateMixin,
  ],
  data() {
    return {
      gridId: 'agent',
      selectedFilter: 'active',
      selectedStatusFilterItemId: null,
      statusFilterItems: [
        { id: '900', text: 'All except Ok' },
        { id: '0', text: 'Ok' },
        { id: '10', text: 'Info' },
        { id: '20', text: 'Warning' },
        { id: '30', text: 'Error' },
      ],
      columns: [
        {
          cell: 'cellActionTemplate',
          field: 'cellActionTemplate',
          locked: true,
          title: ' ',
          filterable: false,
          sortable: false,
          groupable: false,
          width: '40px',
          orderIndex: 0,
          columnMenu: false,
          static: true,
        },
        {
          cell: 'cellAsupHostStatusTemplate',
          locked: true,
          field: 'hostMonitorStatus',
          title: 'Status (Host)',
          orderIndex: 1,
          width: '80px',
          filterable: true,
          filterCell: 'filterSlotTemplate',
          sortable: true,
          groupable: false,
          columnMenu: false,
          meta: {
            removeForTenant: true,
          },
        },
        {
          cell: 'cellAsupTenantStatusTemplate',
          locked: true,
          field: 'tenantMonitorStatus',
          title: 'Status (Tenant)',
          orderIndex: 2,
          width: '80px',
          filterable: true,
          filterCell: 'filterSlotTemplate',
          sortable: true,
          groupable: false,
          columnMenu: false,
          meta: {
            defaultHideForHost: true,
          },
        },
        {
          field: 'tenantName',
          title: 'Organization',
          orderIndex: 3,
          width: '250px',
          meta: {
            removeForTenant: true,
          },
        },
        {
          field: 'hostname',
          title: 'Hostname',
          orderIndex: 4,
          width: '350px',
        },
        {
          field: 'updateTimestampUtc',
          title: 'Updated on',
          orderIndex: 5,
          width: '200px',
          filter: 'date',
          type: 'date',
          format: '{0:g}',
        },
        {
          field: 'agentVersion',
          title: 'Agent Version',
          orderIndex: 6,
          width: '80px',
        },
        {
          field: 'version',
          title: 'Version',
          orderIndex: 7,
          width: '150px',
        },
        {
          field: 'clientsCount',
          title: 'Clients',
          orderIndex: 8,
          width: '80px',
        },
        {
          field: 'os',
          title: 'OS',
          orderIndex: 9,
          width: '100px',
        },
        {
          field: 'osDescription',
          title: 'OS Version',
          orderIndex: 10,
          width: '250px',
        },
        {
          field: 'availableFreeSpaceBytes',
          cell: 'cellfreeSpaceTemplate',
          title: 'Free space',
          orderIndex: 11,
          width: '100px',
        },
        {
          field: 'isHostMonitorOverwritten',
          title: 'Monitor overwritten (Host)',
          orderIndex: 12,
          width: '150px',
          filter: 'boolean',
          type: 'boolean',
          columnMenu: false,
          meta: {
            removeForTenant: true,
          },
        },
        {
          field: 'isTenantMonitorOverwritten',
          title: 'Monitor overwritten (Tenant)',
          orderIndex: 13,
          width: '150px',
          filter: 'boolean',
          type: 'boolean',
          columnMenu: false,
          meta: {
            defaultHideForHost: true,
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    filteredDataItems() {
      const oldDataAgeDays = 60
      const now = moment()

      if (this.selectedFilter === 'active') {
        return this.dataItems
          .filter(x => x.status === 1 || !x.status) // if status is empty (asset not found), we just add it to the active list
          .filter(x => {
            const updateTimestamp = moment(x.updateTimestampUtc)
            const ageDays = now.diff(updateTimestamp, 'days')
            return ageDays < oldDataAgeDays
          })
      } if (this.selectedFilter === 'oldData') {
        return this.dataItems.filter(x => {
          const updateTimestamp = moment(x.updateTimestampUtc)
          const ageDays = now.diff(updateTimestamp, 'days')
          return x.updateTimestampUtc === null || ageDays >= oldDataAgeDays
        })
      }

      return this.dataItem
    },
    result() {
      const data = this.filteredDataItems

      /* Normalize data  */
      data.forEach(item => {
        // Adjust udpateTimestamp. It´s in the local timezone of the system (the property name is incorrect here).
        // Kendo Grid needs a date object, but if we convert it directly to a date object, the browser behavior will automatically use the current browser timezone to adjust this date.
        // So before we convert it to a date object, the timestamp will be adjusted by the current browser timezone
        if (item.updateTimestampUtc !== null && !(item.updateTimestampUtc instanceof Date)) { // adjust timestamp only if it has not already been adjusted
          // eslint-disable-next-line no-param-reassign
          item.updateTimestampUtc = this.adjustByCurrentBrowserTimezone(moment.utc(item.updateTimestampUtc)).toDate()
        }

        if (item.internalNote !== null) {
          // internal note may contain HTML. Strip tags by using browser functionality to parse textContent or innerText
          const div = document.createElement('div')
          div.innerHTML = item.internalNote
          // eslint-disable-next-line no-param-reassign
          item.internalNote = div.textContent || div.innerText || ''
        }

        if (item.account && item.account.accountManager) {
          // eslint-disable-next-line no-param-reassign
          item.account.accountManager.fullname = `${item.account.accountManager.firstname} ${item.account.accountManager.lastname}`
        }
      })

      return process(data, this.dataState)
    },
    selectedStatusFilterItem() {
      if (this.selectedStatusFilterItemId === null) {
        return null
      }
      return this.statusFilterItems.filter(x => x.id === this.selectedStatusFilterItemId)[0]
    },
  },
  created() {
    this.isLoading = true
    AgentService.getAllListAsync({ IncludeDefaultOu: true, maxResultCount: 1000 }, { disableTenantFilter: true })
      .then(result => {
        this.dataItems = result
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    adjustByCurrentBrowserTimezone(dateTime) {
      const browserTz = moment.tz.guess()
      const offset = this.$moment.tz.zone(browserTz).utcOffset(dateTime)
      return dateTime.clone().add(offset, 'minutes')
    },
    getAgentDetailRoute(dataItem) {
      return dataItem.agentVersion === 'v1' ? 'agent-detail.general' : 'agent-detail-v2.general'
    },
  },
}
</script>
